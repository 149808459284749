import React from 'react'
import CarouselDemo from '../Carousel/Carousel';
import Cartas from '../Cartas/Cartas';
import { Header } from '../Header/Header';
import { SidebarDemo } from '../Sidebar/Sidebar';
import './Home.css';
export const Home = () => {
  return (
    <div id='home' className='home'>
        <SidebarDemo/>
        
        <div>
          <Header/>
        </div> 
        <div className='carouselDemoContainer'>
          <br />
          <CarouselDemo/>
          <br />
        </div> 
        <div>
          <br /><br />
          <Cartas/>
        </div> 
    </div>
  )
}
