import { Route, Routes } from 'react-router';
import './App.css';
import { Home } from './Componentes/Home/Home';
import 'primereact/resources/themes/saga-green/theme.css'
import "primereact/resources/primereact.min.css";  
import "primeicons/primeicons.css"; 

function App() {
  return (
    <div className="App">
        <Routes>
            <Route path='/' element={<Home/>}/>
        </Routes>      
    </div>
  );
}

export default App;
