import React,{useEffect} from 'react';
import { Card, Button, CardTitle, CardText, Row, Col, CardImg } from 'reactstrap';
import blusa1 from '../Imagenes/blusa.jpeg'
import blusa2 from '../Imagenes/blusa2.jpeg'
import blusa3 from '../Imagenes/blusa3.jpeg'
import blusa4 from '../Imagenes/blusa4.jpeg'
import './Cartas.css'
import '../Modal/Modal.css'
const Cartas = (props) => {
  useEffect(() => {
    let modal = document.createElement("div");
    modal.setAttribute('id', 'modal');
    modal.setAttribute('class', 'modal');

    // create the child element <div id="modalClose">
    let modalClose = document.createElement('div');
    modalClose.setAttribute('id', 'modalClose');
    modalClose.innerHTML = "&times;";

    // create the child element <img>
    let modalImg = document.createElement('img');
    modalImg.setAttribute('id', 'modalImg');

    // create the child element <div id="modalText"
    let modalText = document.createElement('div');
    modalText.setAttribute('id', 'modalText');

    // open node elements
    document.body.append(modal);
    modal.appendChild(modalClose);
    modal.appendChild(modalImg);
    modal.appendChild(modalText);

    // find all elements with class modalImg
    let imgList = document.querySelectorAll(".modalImg"),
      i;
    for (const img of imgList) {
      // add event click to show modal and add src attribute
      img.addEventListener('click', () => {
        modal.style.display = "block";
        modalImg.src = img.src;
        modalText.innerHTML = img.alt;
      });
    }

    // event, hide modal if user click modalClose "x"
    modalClose.addEventListener("click", function() {
      modal.style.display = "none";
    })
    // event, hide modal if user click on the modal 
    modal.addEventListener("click", function() {
      modal.style.display = "none";
    })
  }, [])
  
  return (
        <div className='containerCartas'>
            <div className='cartaBlusa1'>
                <img src={blusa1} className='fotosBlusas modalImg'/>
               
                <h5>Blusa talla S $10.000, Falda talla 8 o M $25.000 </h5>
                <a href='https://api.whatsapp.com/send/?phone=573014802192&text=%C2%A1Hola%21Quiero+informaci%C3%B3n+de+sus+productos+&type=phone_number&app_absent=0'><Button className='botonContactar'>Contactar para comprar</Button></a>
            </div>
            <div className='cartaBlusa1'>
                <img src={blusa2} className='fotosBlusas modalImg'/>
              
                <h5>Básicas talla M $15.000</h5>
                <a href='https://api.whatsapp.com/send/?phone=573014802192&text=%C2%A1Hola%21Quiero+informaci%C3%B3n+de+sus+productos+&type=phone_number&app_absent=0'><Button className='botonContactar'>Contactar para comprar</Button></a>
            </div>
            <div className='cartaBlusa1'>
                <img src={blusa3} className='fotosBlusas modalImg'/>
                <h1>Nombre producto</h1>
                <h5>Precio: </h5>
                <a href='https://api.whatsapp.com/send/?phone=573014802192&text=%C2%A1Hola%21Quiero+informaci%C3%B3n+de+sus+productos+&type=phone_number&app_absent=0'><Button className='botonContactar'>Contactar para comprar</Button></a>
            </div>
        </div>
  );
};

export default Cartas;
