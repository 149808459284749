import React from 'react'
import './Header.css'
import logo from '../Imagenes/Mi proyecto.png'
import { useEffect } from 'react'
export const Header = () => {
  
  return (
    <div className='Header'>
        <img src={logo} alt="Logo" className='Logo'/>

        <h1 className='lema'>Una vida llena de estilo</h1>
    </div>
  )
}
