import React, { Component } from 'react';
import { Sidebar } from 'primereact/sidebar';
import { Button } from 'primereact/button';
import './Sidebar.css'
import instagram from '../Imagenes/instagram.jpg'
import facebook from '../Imagenes/facebook.png'
export class SidebarDemo extends Component {

    constructor(props) {
        super(props);
        this.state = {
            visibleLeft: false,
            visibleRight: false,
            visibleTop: false,
            visibleBottom: false,
            visibleFullScreen: false,
            visibleCustomToolbar: false
        };
    }

    render() {
        const customIcons = (
            <React.Fragment>
                <button className="p-sidebar-icon p-link mr-1">
                    <span className="pi pi-print" />
                </button>
                <button className="p-sidebar-icon p-link mr-1">
                    <span className="pi pi-arrow-right" />
                </button>
            </React.Fragment>
        );

        return (
            <div>
                <div className="card">
                    <Sidebar visible={this.state.visibleRight} position="right" onHide={() => this.setState({ visibleRight: false })}>
                        <h3>Conocenos en nuestras redes sociales</h3>
                        <a href='https://instagram.com/douceohana?igshid=YmMyMTA2M2Y='><img src={instagram} height='50px' className='imgRedes'/><p>Instagram</p></a>
                        <a href='https://www.facebook.com/douceohana?mibextid=ZbWKwL'><img src={facebook} height='50px' className='imgRedes'/><p>Facebook</p></a>
                    </Sidebar>
                    <Button icon="pi pi-arrow-left" onClick={() => this.setState({ visibleRight: true })} className="mr-2" />
                </div>
            </div>
        )
    }
}
                 